import PropTypes from 'prop-types';
import { Box, Dialog, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Iconify from '../../../../components/Iconify';

const TypographyCustom = styled(Typography)({
  color: 'white',
  fontSize: '14px',
});

const InsufficientBalanceDialog = ({ openInsufficientBalance, setOpenInsufficientBalance }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{ minHeight: 500 }}
      PaperProps={{
        sx: {
          backgroundColor: '#161f2c',
        },
      }}
      open={openInsufficientBalance}
      onClose={() => setOpenInsufficientBalance(false)}
    >
      <Box sx={{ p: 3 }}>
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => setOpenInsufficientBalance(false)}
        />
        <Typography sx={{ color: 'white', textAlign: 'center', fontSize: '20px', fontWeight: 700 }}>
          Insufficient Balance
        </Typography>
        <TypographyCustom sx={{ fontWeight: 700, my: 1 }}>
          The total redemption amount can be unlocked by bets. For more information see{' '}
          <span style={{ color: '#2283f6' }}>Account Balance Management</span>
        </TypographyCustom>

        <Box
          sx={{
            backgroundColor: '#202a39',
            mt: 1,
            p: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
          }}
        >
          <Stack direction="column">
            <TypographyCustom sx={{ textAlign: 'center' }}>Total Balance</TypographyCustom>
            <Typography sx={{ fontSize: '10px', color: 'white', fontWeight: 600, textAlign: 'center', mt: 1 }}>
              RM 0
            </Typography>
          </Stack>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box
              sx={{
                backgroundColor: '#202a39',
                mt: 1,
                p: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
              }}
            >
              <Stack direction="column">
                <TypographyCustom sx={{ textAlign: 'center', color: 'rgb(46, 173, 75)' }}>RM 0</TypographyCustom>
                <Typography sx={{ fontSize: '10px', color: 'white', fontWeight: 600, textAlign: 'center', mt: 1 }}>
                  Available Withrdaw
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                backgroundColor: '#202a39',
                mt: 1,
                p: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
              }}
            >
              <Stack direction="column">
                <TypographyCustom sx={{ textAlign: 'center' }}>RM 0</TypographyCustom>
                <Typography sx={{ fontSize: '10px', color: 'white', fontWeight: 600, textAlign: 'center', mt: 1 }}>
                  Total Redemption Amount
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <button
          //   onClick={() => {
          //   }}
          style={{
            width: '150px',
            height: '44px',
            display: 'block',
            lineHeight: 1.4,
            position: 'relative',
            textAlign: 'center',
            backgroundColor: '#2283f6',
            color: '#fff',
            fontSize: '17px',
            fontFamily: 'sans-serif',
            border: 'none',
            padding: '10px 14px',
            borderRadius: '10px',
            fontWeight: '600',
            marginRight: 'auto',
            marginLeft: 'auto',
            cursor: 'pointer',
            marginBottom: 8,
            marginTop: '28px',
          }}
        >
          Pay Now
        </button>
      </Box>
    </Dialog>
  );
};

InsufficientBalanceDialog.propTypes = {
  openInsufficientBalance: PropTypes.bool,
  setOpenInsufficientBalance: PropTypes.func,
};

export default InsufficientBalanceDialog;
