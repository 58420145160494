import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Dialog, InputBase, NativeSelect, Stack, Typography, styled } from '@mui/material';

import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { useDispatch, useSelector } from '../../../../redux/store';
import { openAddMemberBankDialog } from '../../../../redux/slices/addMemberBankDialog';

const DialogTitle = styled(Typography)(() => ({
  fontSize: '18px',
  color: 'white',
  fontWeight: 'bolder',
}));

const CloseButton = styled(Iconify)(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  right: 16,
  top: 16,
  color: 'rgb(147, 172, 211)',
}));

const CustomSelect = styled(NativeSelect)(({ theme }) => ({
  width: '100%',
  padding: '4px 11px',
  marginTop: 8,
  borderRadius: '15px',
  border: '1px solid #2283f6',
  boxShadow: '0 0 0 2px rgba(23,125,220,.2)',
  color: 'white',
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  color: 'white',
  fontWeight: 700,
  fontSize: '14px',
  flexGrow: 1,
}));

const InputContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '15px',
  border: '1px solid #2283f6',
  boxShadow: '0 0 0 2px rgba(23,125,220,.2)',
  padding: '4px 11px',
  marginTop: 8,
}));

const SaveButton = ({ children, ...other }) => (
  <button
    {...other}
    style={{
      width: '100%',
      height: '44px',
      lineHeight: 1.4,
      position: 'relative',
      textAlign: 'center',
      backgroundColor: '#2283f6',
      color: '#fff',
      fontSize: '17px',
      fontFamily: 'sans-serif',
      border: 'none',
      padding: '10px 14px',
      borderRadius: '50px',
      fontWeight: 'bold',
      marginRight: 8,
      cursor: 'pointer',
      marginBottom: 8,
      marginTop: '20px',
    }}
  >
    {children}
  </button>
);

const ErrorText = ({ children }) => <span style={{ fontSize: '.75rem', color: 'red' }}>{children}</span>;

const RequiredField = () => <span style={{ color: 'red', marginRight: 5 }}>*</span>;

const WithdrawFormDialog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const [bankList, setBankList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [newBankAccountForm, setNewBankAccountForm] = useState({
    accountName: '',
    bank: '',
    accountNumber: '',
  });

  useEffect(() => {
    getBankList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBankAccountForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const getBankList = () => {
    conObj
      .post('transactions/supported_banks.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            setBankList(res.data?.data?.data[0].Bank);
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 5000 });
      });
  };

  const addMemberBank = () => {
    if (isValid()) {
      setIsButtonDisabled(true);

      const requestData = {
        'member_bank-type': 'bank',
        'member_bank-bank_id': newBankAccountForm?.bank,
        'member_bank-account_name': newBankAccountForm?.accountName.trim(),
        'member_bank-account_number': newBankAccountForm?.accountNumber.trim(),
      };

      conObj
        .post('members/bank_add.json', requestData)
        .then((res) => {
          if (res.status === 200) {
            if (res.data?.data?.status === 1) {
              isAddMemberBankDialog?.onClose();

              dispatch(openAddMemberBankDialog({ open: false, onClose: null }));
            }
          }
        })
        .catch((err) => {
          setIsButtonDisabled(false);
          console.log(`Server Error: ${err.message}`);
          enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 5000 });
        });
    }
  };

  const isValid = () => {
    let valid = true;

    const _errors = {};

    if (!newBankAccountForm.accountName.trim()) {
      valid = false;
      _errors.accountName = translate('x_is_required', { x: translate('account_name') });
    } else if (newBankAccountForm.accountName.trim().length < 5) {
      valid = false;
      _errors.accountName = translate('account_name_must_be_4');
    } else {
      _errors.accountName = '';
    }

    if (!newBankAccountForm.bank) {
      valid = false;
      _errors.bank = translate('please_select_x', { x: translate('bank_account') });
    } else {
      _errors.bank = '';
    }

    const accNumberLenght = newBankAccountForm.accountNumber.trim().length;
    const selectedBank = bankList.find((f) => f.id === newBankAccountForm?.bank);

    if (!newBankAccountForm.accountNumber.trim()) {
      valid = false;
      _errors.accountNumber = translate('x_is_required', { x: translate('account_number') });
    } else if (parseInt(selectedBank?.min_length, 10) > 0 || parseInt(selectedBank?.max_length, 10) > 0) {
      if (parseInt(selectedBank?.min_length, 10) > 0 && accNumberLenght < parseInt(selectedBank?.min_length, 10)) {
        valid = false;
        _errors.accountNumber = translate('minimum_length_is_x', { x: selectedBank?.min_length });
      } else if (
        parseInt(selectedBank?.max_length, 10) > 0 &&
        accNumberLenght > parseInt(selectedBank?.max_length, 10)
      ) {
        valid = false;
        _errors.accountNumber = translate('maximum_length_is_x', { x: selectedBank?.max_length });
      } else {
        _errors.accountNumber = '';
      }
    } else {
      _errors.accountNumber = '';
    }

    setErrors(_errors);

    return valid;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{ minHeight: 500 }}
      PaperProps={{
        sx: {
          backgroundColor: '#161f2c',
        },
      }}
      className="modal-container container-no-scrollbar"
      open={isAddMemberBankDialog?.open}
      onClose={() => dispatch(openAddMemberBankDialog({ open: false, onClose: null }))}
    >
      {/* Title */}
      <Box sx={{ p: 3, background: 'linear-gradient(#00326d,#111923)' }}>
        <DialogTitle>{translate('withdraw_information')}</DialogTitle>
        <CloseButton
          icon={'ep:close'}
          width={24}
          height={24}
          onClick={() => dispatch(openAddMemberBankDialog({ open: false, onClose: null }))}
        />
      </Box>

      <Box sx={{ p: { sm: 3, xs: 1 } }}>
        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <Typography sx={{ color: 'white' }}>
              <RequiredField />
              {translate('bank')}
            </Typography>
          </Stack>

          <CustomSelect
            name="bank"
            value={newBankAccountForm?.bank}
            onChange={(e) => handleChange(e)}
            disableUnderline={true}
          >
            <option value="" />
            {bankList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </CustomSelect>
          {errors?.bank && <ErrorText>{errors?.bank}</ErrorText>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <Typography sx={{ color: 'white' }}>
              <RequiredField />
              {translate('account_holder_name')}
            </Typography>
          </Stack>

          <InputContainer direction="row">
            <CustomInput
              value={newBankAccountForm.accountName}
              name="accountName"
              onChange={(e) => handleChange(e)}
              placeholder={translate('name_of_the_account_holder')}
            />
          </InputContainer>
          {errors?.accountName && <ErrorText>{errors?.accountName}</ErrorText>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <Typography sx={{ color: 'white' }}>
              <RequiredField />
              {translate('account_number')}
            </Typography>
          </Stack>

          <InputContainer direction="row">
            <CustomInput
              value={newBankAccountForm?.accountNumber}
              name="accountNumber"
              onChange={(e) => handleChange(e)}
              placeholder={translate('fill_in_bank_account')}
            />
          </InputContainer>
          {errors?.accountNumber && <ErrorText>{errors?.accountNumber}</ErrorText>}
        </Box>

        <SaveButton
          disabled={isButtonDisabled}
          onClick={() => {
            addMemberBank();
          }}
        >
          {translate('save')}
        </SaveButton>
      </Box>
    </Dialog>
  );
};

export default WithdrawFormDialog;
