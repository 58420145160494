import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  openPageLoadingCount: 0,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // OPEN PAGE LOADING SCREEN
    openLoadingScreen(state) {
      state.openPageLoadingCount += 1;
    },

    // CLOSE PAGE LOADING SCREEN, DO NOT FORGET TO CALL THIS AFTER OPEN AS IT WILL NOT CLOSE THE LOADING SCREEN
    closeLoadingScreen(state) {
      if (state.openPageLoadingCount > 0) state.openPageLoadingCount -= 1;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openLoadingScreen, closeLoadingScreen } = slice.actions;
