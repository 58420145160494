import React, { useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Deposit from './deposit/Deposit';
import Withdraw from './withdraw/Withdraw';

import { useDispatch, useSelector } from '../../../redux/store';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import CustomModal from '../../../components/CustomModal';
import useLocales from '../../../hooks/useLocales';

const DepositWithdrawDialog = () => {
  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const [activeTab, setActiveTab] = useState('1');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const keyUpHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();

        dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
      }
    };

    document.addEventListener('keyup', keyUpHandler);

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener('keyup', keyUpHandler);
    };
  }, []);

  return (
    <CustomModal
      open={isOpenTransactionsDialog.open}
      onClose={() => dispatch(openTransactionsDialog({ open: false, isDeposit: true }))}
      isBackdropClosable={false}
      // sx={{ overflow: 'hidden' }}
    >
      <Box
        sx={{
          // backgroundColor: '#161f2c',
          // borderRadius: '20px',
          // height: '80vh',
          // width: '96vw',
          // maxWidth: '500px',
          // overflow: 'auto',
          p: {
            xl: '50px 24px 24px',
            lg: '50px 24px 24px',
            md: '50px 24px 24px',
            sm: '50px 24px 24px',
            xs: '50px 8px 8px',
          },
          // m: 'auto',
          // boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          // position: 'relative',
          // marginTop: '50px',
        }}
        // className="modal-container container-no-scrollbar"
      >
        <TabContext value={activeTab}>
          <Box sx={{ mb: 4 }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // indicatorColor="transparent"
              TabIndicatorProps={{
                style: { backgroundColor: 'transparent' },
              }}
              sx={{ background: '#1d2532', width: 'fit-content', minHeight: 'unset', borderRadius: '15px' }}
            >
              <Tab
                label={translate('deposit')}
                value="1"
                sx={{
                  background: (theme) => (activeTab === '1' ? theme.palette.primary.main : '#1d2532'),
                  minHeight: 'unset',
                  marginRight: '10px !important',
                  p: '5px 10px',
                  fontSize: '14px',
                  borderRadius: '16px',
                  color: activeTab === '1' ? 'white !important' : '#637381',
                }}
              />
              <Tab
                label={translate('withdraw')}
                value="2"
                sx={{
                  background: activeTab === '2' ? 'rgb(237, 29, 73)' : '#1d2532',
                  minHeight: 'unset',
                  p: '5px 10px',
                  fontSize: '14px',
                  borderRadius: '16px',
                  color: activeTab === '2' ? 'white !important' : '#637381',
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Deposit />
          </TabPanel>
          <TabPanel value="2">
            <Withdraw />
          </TabPanel>
        </TabContext>
      </Box>
    </CustomModal>
  );
};

export default DepositWithdrawDialog;
