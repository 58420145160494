/* eslint-disable camelcase */
// @mui
import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getYear } from 'date-fns';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: '#55657e',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

const Footer = () => {
  const { siteSettings } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();

  const {
    // config_phonenumber,
    config_telegram,
    config_wechat,
    config_whatsapp,
    config_facebook,
    config_messenger,
    config_viber,
    config_twitter,
    config_instagram,
    config_youtube,
    config_line,
  } = siteSettings || {};

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied!');
  };

  const copyrightText = useMemo(() => {
    if (siteSettings?.config_footer_copyright) {
      const newText = siteSettings?.config_footer_copyright
        .replace('[year]', getYear(new Date()))
        .replace('[name]', siteSettings?.config_name);

      console.log(newText);

      return newText;
    }

    return '';
  }, [siteSettings?.config_footer_copyright, siteSettings?.config_name]);

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  return (
    <Box sx={{ m: '20px auto 0', p: '20px 20px 100px', backgroundColor: '#0b1712', overflow: 'hidden' }}>
      {/* Player/Winner */}
      {/* <Grid container spacing={1}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>915, 190</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify icon={'fa:group'} width={25} height={25} sx={{ color: 'rgb(152, 172, 208)' }} />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>970, 957</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify
                  icon={'healthicons:award-trophy'}
                  width={25}
                  height={25}
                  sx={{ color: 'rgb(152, 172, 208)' }}
                />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid> */}

      <Grid container sx={{ mt: 3 }}>
        {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
          <Stack direction="column" spacing={2}>
            <StyledTypography sx={{ color: '#93acd3 !important' }}>GAMES</StyledTypography>
            <StyledTypography>CRASH</StyledTypography>
            <StyledTypography>PLINKO</StyledTypography>
            <StyledTypography>LUCKY COLOUR</StyledTypography>
            <StyledTypography>DICE</StyledTypography>
            <StyledTypography>LIMBO</StyledTypography>
          </Stack>
        </Grid> */}
        {/* <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <Stack direction="column" spacing={2}>
            <StyledTypography sx={{ color: '#93acd3 !important' }}>HELPS</StyledTypography>
            <StyledTypography sx={{ color: '#55657e', fontSize: '12px', textTransform: 'unset !important' }}>
              KYC Policy
            </StyledTypography>
            <StyledTypography sx={{ textTransform: 'unset !important' }}>Privacy Policy</StyledTypography>
            <StyledTypography sx={{ textTransform: 'unset !important' }}>Responsible Gaming</StyledTypography>
            <StyledTypography sx={{ textTransform: 'unset !important' }}>Terms and Condition</StyledTypography>
            <StyledTypography sx={{ textTransform: 'uppercase' }}>FAQ</StyledTypography>
          </Stack>
        </Grid> */}
      </Grid>

      {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
        <Grid container spacing={3}>
          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/google.png')}
              alt="Google"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>
          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/fb.png')}
              alt="Fb"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>
          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/caleta.png')}
              alt="Caleta"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>

          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/evoplay.png')}
              alt="Evoplay"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>

          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/ezugi.png')}
              alt="Ezugi"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>

          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/red_tiger.png')}
              alt="Red Tiger"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>

          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/kiron.png')}
              alt="Kiron"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>

          <Grid item xl={12 / 8} lg={12 / 8} md={12 / 8} sm={12 / 4} xs={12 / 3}>
            <Image
              src={require('../../assets/powered/evolution.png')}
              alt="Evolution"
              sx={{
                height: { xl: '40px', lg: '40px', md: '40px', sm: 'auot', xs: 'auto' },
                width: { xl: 'auto', lg: 'auto', md: 'auto', sm: '100%', xs: '100%' },
              }}
            />
          </Grid>
        </Grid>
      </Box> */}

      <hr
        style={{ height: '.5px', background: 'rgb(85, 101, 126, .2)', borderWidth: 0, marginTop: 16, marginBottom: 16 }}
      />
      <StyledTypography sx={{ fontSize: '9px !important', textAlign: 'center' }}>{copyrightText}</StyledTypography>
    </Box>
  );
};

export default Footer;
