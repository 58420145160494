import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

const initialState = {
  openClaimAngpao: {
    open: false,
    bonusAmount: '',
  },
};

const slice = createSlice({
  name: 'claimAngpaoDialog',
  initialState,
  reducers: {
    openClaimAngpaoDialog(state, action) {
      state.openClaimAngpao = {
        open: action?.payload?.open,
        bonusAmount: action?.payload?.bonusAmount,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openClaimAngpaoDialog } = slice.actions;
