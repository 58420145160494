// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ms = {
  game: `Permainan`,
  games: `Permainan`,
  center: `Pusat`,
  game_history: `Sejarah Permainan`,
  coupon_code: `Kod Kupon`,
  telegram: `Telegram`,
  customer_service: `Khidmat Pelanggan`,
  player: `Pemain`,
  winner: `Pemenang`,
  all_bets: `Semua Pertaruhan`,
  my_bets: `Pertaruhan Saya`,
  name: `Nama`,
  time: `Masa`,
  bet_amount: `Jumlah Pertaruhan`,
  prediction: `Ramalan`,
  payout: `Pembayaran`,
  login: `Log masuk`,
  register: `Daftar`,
  username_placeholder: `Nama pengguna`,
  please_input_your_x: `Sila Masukkan {{x}} Anda`,
  captcha: `CAPTCHA`,
  forgot_password: `Lupa kata laluan`,
  minor_note: `Untuk melawat laman web ini, pastikan anda berumur lebih dari 18 tahun dan bersetuju`,
  terms_of_service: `Syarat Perkhidmatan`,
  dont_have_account: `Tiada akaun?`,
  sign_up: `Daftar`,
  username: `Nama pengguna`,
  email: `E-mel`,
  full_name: `Nama penuh`,
  confirm_18: `Saya mengesahkan bahawa saya berumur 18 tahun dan saya telah membacanya`,
  already_have_account: `Sudah mempunyai akaun?`,
  share: `Kongsi`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Tambah lagi`,
  details: `Butiran`,
  amount: `Jumlah`,
  extra: `Tambahan`,
  deposit_bonus: `Bonus Deposit`,
  do_not_participate: `Jangan sertai promosi`,
  payment_problem: `Masalah Pembayaran`,
  available_balance: `Baki yang ada`,
  withdraw: `tarik diri`,
  min: `Min:`,
  max: `Maks:`,
  notifications: `Pemberitahuan`,
  invalid_x: `{{x}} tidak sah`,
  captcha_not_match: `Captcha Tidak Padan`,
  invalid_x_must_be_atleast_y_characters: `{{x}} tidak sah, mestilah sekurang-kurangnya {{y}} aksara`,
  x_is_required: `{{x}} diperlukan`,
  phone_number: 'Nombor telefon',
  phone_num_x_max: `Nombor Telefon maksimum {{x}} digit`,
  email_sent: `E-mel dihantar, sila semak e-mel anda.`,
  enter_valid_email: `Sila masukkan e-mel yang sah.`,
  confirm: `Sahkan`,
  password_atleast_6_characters: `Kata laluan mestilah sekurang-kurangnya 6 aksara.`,
  password_reset_success: `Kejayaan tetapan semula kata laluan!.`,
  new_password: `Kata laluan baharu`,
  bank: 'Bank',
  bank_transfer: `Pemindahan bank`,
  bank_name: `Nama bank`,
  account_name: `Nama akaun`,
  account_number: `Account Number`,
  drop_or_select: `Lepaskan atau Pilih fail`,
  drop_files_here: `Lepaskan fail di sini`,
  transfer_to: `Pemindahan Kepada`,
  upload_receipt: `Muat Naik Resit`,
  select_bonus: `Pilih Bonus`,
  no_promotion: `Tiada Promosi`,
  please_upload_receipt: `Sila muat naik resit`,
  bank_account: `Akaun bank`,
  add_account: `Tambah akaun`,
  account_holder_name: `Nama Pemegang Akaun`,
  name_of_the_account_holder: `Nama Pemegang Akaun`,
  fill_in_bank_account: `Isikan Akaun Bank`,
  withdraw_information: `Tarik balik Maklumat`,
  note: `Catatan`,
  amount_withdrawn: `Jumlah yang dikeluarkan`,
  amount_no_exceed_balance: `Jumlah Pengeluaran tidak boleh melebihi baki yang ada`,
  minimum_withdraw_amount_is_x: `Jumlah Pengeluaran Minimum ialah {{x}}`,
  maximum_withdraw_amount_is_x: `Amaun Pengeluaran Maksimum ialah {{x}}`,
  transaction_history: `sejarah transaksi`,
  date_from: `Tarikh dari`,
  date_to: `Tarikh Kepada`,
  account_name_must_be_4: `Nama Akaun mestilah lebih besar daripada 4 panjang`,
  account_number_must_be_x: `Nombor akaun mestilah 10-14 panjang`,
  minimum_length_is_x: `Panjang minimum ialah {{x}}`,
  maximum_length_is_x: `Panjang maksimum ialah {{x}}`,
  payment_method: `Kaedah Pembayaran`,
  mode: `Mod`,
  no_bank_account: `Anda tidak mempunyai sebarang akaun untuk dikeluarkan, Tambah sekarang`,
  please_select_bank: `Sila pilih Akaun Bank`,

  bet_history: `Sejarah Pertaruhan`,
  game_provider: `Penyedia Permainan`,
  bet_count: `Kiraan Pertaruhan`,
  win_loss: `Menang/Kalah`,
  rebate: `Rebat`,
  turnover: `Perolehan`,
  game_category: `Kategori Permainan`,

  you_have_x_unread_messages: `Anda mempunyai {{x}} mesej yang belum dibaca`,
  deposit_amount_between_x_and_y: `Amaun deposit mestilah antara {{x}} dan {{y}}`,
  minimum_amount_is_x_y: `Jumlah minimum ialah {{x}}{{y}}`,
  from_selected_promotion: 'dari promosi terpilih',
  from_selected_bank: 'dari bank terpilih',
  deposit_from_bank: 'Deposit Dari Bank',
  payment_draft:
    'Jika anda mengesahkan anda telah membayar, sila tunggu sebentar kerana pembayaran mungkin masih diproses',

  // all
  all: `Semua`,
  title: `Tajuk`,
  date: `Tarikh`,
  type: `Taip`,
  status: `Status`,
  remark: `Teguran`,
  category: `kategori`,
  please_select_x: `Sila pilih {{x}}`,
  save: `Jimat`,

  change_password: `Tukar kata laluan`,
  current_password: `Kata Laluan Semasa`,
  confirm_password: `Sahkan Kata Laluan Baharu`,
  submit: `Hantar`,
  password_changed: `Kata Laluan Ditukar`,
  x_must_be_y_characters: `{{x}} mestilah sekurang-kurangnya {{y}} aksara`,
  reset_password: `Menetapkan semula kata laluan`,
  casino: `Kasino`,
  slots: `Slot`,
  live_casino: `Kasino Langsung`,
  fishing: `Memancing`,
  sports: `sukan`,
  cards: `Kad`,
  clear: `Jelas`,
  search: `Cari`,
  new: `Baru`,
  logout: `Log keluar`,
  choose_date: `Pilih Tarikh`,
  select_both_date: `Pilih kedua-dua Tarikh Dari dan Kepada`,
  from: `daripada`,
  to: `Kepada`,
  apply: `Mohon`,
  promotions: `Promosi`,
  learn_more: `KETAHUI LEBIH LANJUT`,
  password_doesnt_match: `Kata laluan tidak sepadan`,
  cancel: `Batal`,
  confirm_to_pay: `Sahkan Untuk Bayar`,
  confirm_to_withdraw: `Sahkan untuk menarik diri`,
  deposit_amount: `Jumlah Deposit`,
  loading_please_wait: `Muat turun sila tunggu`,
  account: `Akaun`,
  profile: `Profil`,
  bank_list: `Senarai Bank`,
  in_maintenance: `Dalam Penyelenggaraan`,
  birthday: `Hari Jadi`,
  birthday_bonus: `Masukkan hari lahir anda untuk bonus! 🎉🎁`,
  referral_code: `Kod rujukan`,
  referral: `Rujukan`,
  my_referral: `Rujukan Saya`,
  my_claim: `Tuntutan Saya`,
  claim_profit: `TUNTUTAN UNTUNG`,
  available_profit: `Keuntungan yang Tersedia`,
  claimed_profit: `Keuntungan yang Dituntut`,
  all_time_profit: `Keuntungan Sepanjang Masa`,
  referral_note_on_x: `Peroleh keuntungan setiap kali rakan anda bermain di {{x}}`,
  tier: `Peringkat`,
  commission: `Suruhanjaya`,
  referred_member: `Ahli yang dirujuk`,
  profit_earned: `Keuntungan Diperolehi`,
  account_information: `Maklumat Akaun`,
  invite_friends: `Menjemput kawan-kawan`,
  how_it_work: `Bagaimana ia berfungsi?`,
  invite_note: `Dapatkan bonus rujukan eksklusif apabila rakan anda mendaftar menggunakan kod rujukan anda!`,
  share_via: `Kongsi melalui`,
  referral_link: `Pautan Rujukan`,
  total_profit: `Jumlah Keuntungan`,
  copied: `Disalin`,
  nothing_to_claim: `Tiada apa-apa untuk dituntut`,
  claim_history: `Sejarah Tuntutan`,
  downline: `Downline Saya`,
  last_x_digit_bank_ref_number: `{{x}} digit terakhir nombor Ruj Bank`,
  bank_ref_number_validation_x: `Nombor Rujukan Bank diperlukan dan mestilah {{x}} panjang aksara`,
  sort: 'Sort',
  copy_your_referral:
    'Salin pautan rujukan anda dan hantar ke media sosial untuk mendapatkan kredit dari downline anda!',
  how_to_get_referral_bonus: 'Cara Mendapatkan Bonus Rujukan Sahabat?',
  steps_to_claim: 'Langkah-Langkah Untuk Tuntutan Bonus',
  share_your_referral_code:
    '1. Kongsi kod rujukan anda kepada rakan-rakan untuk mendaftar akaun melalui viber, messenger, telegram, facebook, twitter, atau instagram.',
  after_friend_register:
    '2. Selepas rakan mendaftar dengan ZK7, kedua-dua rujukan dan ahli rujukan perlu membuat deposit untuk menuntut bonus ini.',
  refer_a_friend_bonus: '3. Pengiraan bonus rujukan sahabat:',
  turnover_played: 'Putaran dimainkan x Peratus Komisen = Bonus Rujukan Sahabat',
  example: 'Contoh:',
  turnover_formula_x: 'Putaran (1,000,000) x 0.002% (Peratus Komisen) = 2,000{{x}}',
  note_bonus_only: '*Nota: Bonus hanya boleh dituntut pada hari berikutnya selepas 12:00 pagi',
  note_bonus_only_x: '*Nota: Bonus hanya boleh dituntut pada hari berikutnya selepas {{x}}',
  access_dashboard:
    '4. Akses papan pemuka untuk menyemak keuntungan yang layak, tier komisen, sejarah tuntutan, dan downline.',
  click_this_button_to_claim: 'Klik butang ini untuk menuntut Bonus Rujukan Sahabat',
  commision_tier: 'Tier Komisen',
  member_downline: 'Ahli Downline',
  bonus_tutorial_video: 'Bonus Tutorial Video',
  popularity: 'Populariti',
  no_balance: 'Anda tidak mempunyai baki yang mencukupi untuk meneruskan',
  default: 'Default',
  claim_bonus: 'Tuntutan Bonus',
  claimed: 'Dituntut',
  bonus_claimed: 'Bonus Dituntut',
  connect_with_us: `Berhubung dengan kami`,
  level: `Tahap`,
  online_banking: 'Online Banking',
  brands: 'Brands',
  no_result: 'Tiada keputusan',
};

export default ms;
