import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Grid, InputBase, Snackbar, Stack, Typography } from '@mui/material';
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplateNoReload,
//   validateCaptcha,
// } from 'react-simple-captcha';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';
import Image from '../../../../components/Image';

import useAuth from '../../../../hooks/useAuth';
import { useDispatch, useSelector } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

const _loginForm = {
  userUsername: '',
  userPassword: '',
  captcha: '',
};

const Login = ({ setOpenForgotPasswordDialog, setActiveTab }) => {
  const { login } = useAuth();

  const { siteSettings } = useSelector((x) => x.lookup);

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [loginForm, setLoginForm] = useState(_loginForm);
  const [formError, setFormError] = useState({});
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
    type: 'success',
  });
  const [validationErrors, setValidationErrors] = useState(null);

  const { config_telegram: configTelegram, config_viber: configViber } = siteSettings || {};

  // useEffect(() => {
  //   setTimeout(() => {
  //     loadCaptchaEnginge(4);
  //   }, 1000);
  // }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [loginForm]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setLoginForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async () => {
    if (!isValid()) return;

    setIsLoading(true);

    const requestData = {
      'user-username': loginForm?.userUsername.trim(),
      'user-password': loginForm?.userPassword.trim(),
    };

    try {
      await login(requestData);

      // close login/register dialog
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  const isValid = () => {
    let valid = true;
    const _error = {};

    if (loginForm?.userUsername?.trim()?.length < 3) {
      valid = false;
      _error.userUsername = translate('invalid_x', { x: translate('username') });
    } else {
      _error.userUsername = '';
    }

    if (loginForm?.userPassword?.trim()?.length < 5) {
      valid = false;
      _error.userPassword = translate('invalid_x', { x: translate('password') });
    } else {
      _error.userPassword = '';
    }

    // if (validateCaptcha(loginForm?.captcha?.trim(), false) === true) {
    //   _error.userCaptcha = '';
    // } else {
    //   valid = false;
    //   _error.userCaptcha = translate('captcha_not_match');
    // }

    setFormError((prevState) => _error);

    return valid;
  };

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '10px' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'mdi-light:account'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            name="userUsername"
            value={loginForm?.userUsername}
            placeholder={translate('username_placeholder')}
            //   InputLabelProps={{
            //     style: { color: '#999999' },
            //  }}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
            onChange={(e) => handleFormChange(e)}
          />
        </Stack>
        {formError?.userUsername && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userUsername}</span>}
      </Box>

      <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            borderRadius: '10px',
            border: '1px solid #202a39',
            height: '44px',
            p: '8.5px 13px',
            mt: '10px',
          }}
        >
          <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            type={showPassword ? 'text' : 'password'}
            name="userPassword"
            value={loginForm?.userPassword}
            placeholder={translate('please_input_your_x', { x: translate('password') })}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
            onChange={(e) => handleFormChange(e)}
          />
          <Iconify
            icon={showPassword ? 'ph:eye-light' : 'ph:eye-closed-light'}
            width={24}
            height={24}
            sx={{ cursor: 'pointer', color: 'rgb(147, 172, 211)' }}
            onClick={() => setShowPassword((prevState) => !prevState)}
          />
        </Stack>
        {formError?.userPassword && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userPassword}</span>}
      </Box>

      {/* <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'iconamoon:shield-yes-thin'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            name="captcha"
            placeholder={translate('captcha')}
            value={loginForm?.captcha}
            onChange={(e) => handleFormChange(e)}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
          />
          <Box sx={{ mb: '-5px' }}>
            <LoadCanvasTemplateNoReload />
          </Box>
        </Stack>
        {formError?.userCaptcha && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userCaptcha}</span>}
      </Box> */}

      <Stack sx={{ mb: 4 }} spacing={1}>
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            color: '#55657e',
            textDecoration: 'underline',
            mt: 2,
          }}
        >
          {`${translate('forgot_password')}? ${translate('contact_us_through')}`}
        </Typography>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {configTelegram && (
            <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5}>
              <Image
                src={require('../../../../assets/social/telegram.png')}
                onClick={() => openSocial(configTelegram)}
                alt="Adult"
                sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
              />
            </Grid>
          )}
          {configViber && (
            <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5}>
              <Image
                src={require('../../../../assets/social/viber.png')}
                onClick={() => openSocial(configViber)}
                alt="Adult"
                sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
              />
            </Grid>
          )}
        </Stack>
      </Stack>

      {/* <Typography
        onClick={() => setOpenForgotPasswordDialog(true)}
        sx={{
          fontSize: '14px',
          fontFamily: 'sans-serif',
          fontWeight: '400',
          color: '#55657e',
          cursor: 'pointer',
          textDecoration: 'underline',
          mt: 2,
          mb: 4,
        }}
      >
        {`${translate('forgot_password')}?`}
      </Typography> */}

      <LoadingButton
        onClick={() => onSubmit()}
        fullWidth
        variant="contained"
        sx={{ width: '100%', mb: 1 }}
        loading={isLoading}
      >
        {translate('login')}
      </LoadingButton>

      <Typography
        sx={{ fontSize: '12px', fontFamily: 'sans-serif', fontWeight: 500, color: '#55657e', cursor: 'pointer' }}
      >
        {translate('minor_note')}{' '}
        <span style={{ textDecoration: 'underline', color: '#2283f6' }}>{translate('terms_of_service')}</span>
      </Typography>

      <Typography
        onClick={() => setActiveTab('2')}
        sx={{
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'sans-serif',
          color: 'white',
          mt: 2,
          cursor: 'pointer',
        }}
      >
        {translate('dont_have_account')}{' '}
        <span style={{ textDecoration: 'underline', color: '#2283f6' }}>{translate('sign_up')}</span>
      </Typography>
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Login.propTypes = {
  setOpenForgotPasswordDialog: PropTypes.func,
  setActiveTab: PropTypes.func,
};

export default Login;
