import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import Image from '../../Image';
// hooks
import useLocales from '../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../redux/store';
import { setActiveTab } from '../../../redux/slices/game';

import controllerSvg from '../../../assets/home/controller.png';
import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../Iconify';
import conObj from '../../../utils/connection-assistant';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
  onCloseSidebar: PropTypes.func,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, onCloseSidebar = () => {}, ...other }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { translate, currentLang } = useLocales();

  const { gameCategories, activeTab } = useSelector((x) => x.game);

  const { siteSettings } = useSelector((x) => x.lookup);

  const isDesktop = useResponsive('up', 'lg');

  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  const [companies, setCompanies] = useState([]);

  const isAuthenticated = localStorage.getItem('accessToken');

  const getCompanies = () => {
    setIsLoadingCompanies(true);
    conObj
      .post(`games/all.json?limit=1&page=0`)
      .then((res) => {
        if (res.status === 200) {
          // res.data?.data?.data
          // set appversion to localstorage
          // res.data?.data.appversion
          const resData = res.data?.data?.data;

          if (resData?.length) {
            const _companies = [];

            resData.forEach((item) => {
              if (item?.type === 'company' && item?.games?.length)
                _companies.push({
                  key: item.key,
                  name: item.name,
                  iconUrl: item.icon_url,
                  slug: item.slug,
                  gamesCount: item.games.length,
                });
            });

            setCompanies(_companies);
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
      })
      .finally(() => setIsLoadingCompanies(false));
  };

  useEffect(() => {
    getCompanies();
  }, [currentLang]);

  const {
    // config_phonenumber,
    config_telegram: configTelegram,
    config_wechat: configWechat,
    config_whatsapp: configWhatsapp,
    config_facebook: configFacebook,
    config_messenger: configMessenger,
    config_viber: configViber,
    config_twitter: configTwitter,
    config_instagram: configInstagram,
    config_youtube: configYoutube,
    config_line: configLine,
  } = siteSettings || {};

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  const makeGameCategory = (_category) => {
    let nav = '';

    if (_category?.slug.toLowerCase().indexOf('slots') !== -1) nav = require('../../../assets/menu/nav-slot.png');

    if (_category?.slug.toLowerCase().indexOf('live-casino') !== -1) nav = require('../../../assets/menu/nav-live.png');

    if (_category?.slug.toLowerCase().indexOf('fishing') !== -1) nav = require('../../../assets/menu/nav-fish.png');

    if (_category?.slug.toLowerCase().indexOf('card') !== -1) nav = require('../../../assets/menu/nav-card.png');

    if (_category?.slug.toLowerCase().indexOf('sports') !== -1) nav = require('../../../assets/menu/nav-sport.png');

    if (_category?.slug.toLowerCase().indexOf('e-sports') !== -1)
      nav = require('../../../assets/menu/nav-e-sports.png');

    return (
      <NavBtnWrapper
        src={nav}
        title={_category?.name}
        onClick={() => {
          dispatch(setActiveTab(_category?.key));
          if (!isDesktop) onCloseSidebar();
          navigate('/home', { state: { isFromOtherPage: window.location.pathname !== '/home' } });
        }}
      />
    );
  };

  return (
    <Box {...other}>
      <Accordion sx={{ mb: 1 }}>
        <AccordionSummary
          expandIcon={<Iconify icon="mingcute:up-fill" sx={{ width: '30px', height: '30px' }} />}
          sx={{
            aspectRatio: '380/120',
            minHeight: '60px',
            p: 1,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/home', { state: { isFromOtherPage: window.location.pathname !== '/home' } })}
        >
          {/* <Box
            sx={{
              mb: 1,
              aspectRatio: '380/120',
              minHeight: '60px',
              p: 1,
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/home')}
          > */}
          <Image
            src={require('../../../assets/home/menu1.png')}
            alt="Game Center"
            sx={{
              borderRadius: '.5rem',
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: 0,
              left: 0,
              cursor: 'pointer',
            }}
          />
          <Image src={controllerSvg} alt="Controller" sx={{ height: '100%', minWidth: '30px', mr: 1, zIndex: 10 }} />
          <Stack direction="column" sx={{ zIndex: 10 }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 900, color: '#fff', fontFamily: 'sans-serif' }}>
              {translate('game')}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', fontWeight: 900, color: 'rgb(255, 255, 255, 0.65)', fontFamily: 'sans-serif' }}
            >
              {translate('brands')}
            </Typography>
          </Stack>
          {/* </Box> */}
        </AccordionSummary>
        <AccordionDetails sx={{ p: 1 }}>
          {companies.map((item, idx) => (
            <NavBtnWrapper
              key={idx}
              src={item.iconUrl}
              title={item?.name}
              onClick={() => {
                dispatch(setActiveTab(item?.key));
                if (!isDesktop) onCloseSidebar();
                navigate('/home', { state: { isFromOtherPage: window.location.pathname !== '/home' } });
              }}
              sx={{ backgroundColor: activeTab === item?.key ? '#2283f6' : '#082d1f' }}
            />
          ))}
        </AccordionDetails>
      </Accordion>

      {gameCategories.length > 0 && gameCategories.map((item, index) => makeGameCategory(item))}

      {isAuthenticated ? (
        <Box sx={{ mb: 1 }}>
          <Box
            onClick={() => {
              navigate('/history/bet');
              if (!isDesktop) onCloseSidebar();
            }}
            sx={{
              justifyContent: 'flex-start',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#082d1f',
              borderRadius: '10px',
              cursor: 'pointer',
              p: '8px 12px',
              position: 'relative',
            }}
          >
            <Image
              src={require('../../../assets/home/history.png')}
              sx={{ width: '24px', mr: '12px', mb: 0 }}
              alt="History"
            />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                fontFamily: 'sans-serif',
                lineHeight: '1.5',
                textAlign: 'left',
                textDecoration: 'none',
                color: '#fff',
              }}
            >
              {translate('game_history')}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}

      {isAuthenticated ? (
        <Box sx={{ mb: 1 }}>
          <Box
            onClick={() => {
              navigate('/history/transaction');
              if (!isDesktop) onCloseSidebar();
            }}
            sx={{
              justifyContent: 'flex-start',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#082d1f',
              borderRadius: '10px',
              cursor: 'pointer',
              p: '8px 12px',
              position: 'relative',
            }}
          >
            <Image
              src={require('../../../assets/home/transaction.png')}
              sx={{ mr: '12px', width: '24px', height: '24px' }}
            />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                fontFamily: 'sans-serif',
                lineHeight: '1.5',
                textAlign: 'left',
                textDecoration: 'none',
                color: '#fff',
              }}
            >
              {translate('transaction_history')}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}

      {/* <Box sx={{ mb: 1 }}>
        <Box
          sx={{
            justifyContent: 'flex-start',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#082d1f',
            borderRadius: '10px',
            cursor: 'pointer',
            p: '8px 12px',
            position: 'relative',
          }}
        >
          <Image
            src={require('../../../assets/home/coupon.png')}
            sx={{ width: '24px', mr: '12px', mb: 0 }}
            alt="History"
          />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: 'sans-serif',
              lineHeight: '1.5',
              textAlign: 'left',
              textDecoration: 'none',
              color: '#fff',
            }}
          >
            Coupon Code
          </Typography>
        </Box>
      </Box> */}

      {/* <Box sx={{ mb: 1 }}>
        <Box
          sx={{
            justifyContent: 'flex-start',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#082d1f',
            borderRadius: '10px',
            cursor: 'pointer',
            p: '8px 12px',
            position: 'relative',
          }}
        >
          <Iconify icon="bxl:telegram" width={24} height={24} sx={{ mr: '12px', color: '#2956d2' }} />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: 'sans-serif',
              lineHeight: '1.5',
              textAlign: 'left',
              textDecoration: 'none',
              color: '#fff',
            }}
          >
            Telegram
          </Typography>
        </Box>
      </Box> */}

      {/* <Box sx={{ mb: 1 }}>
        <Box
          sx={{
            justifyContent: 'flex-start',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#082d1f',
            borderRadius: '10px',
            cursor: 'pointer',
            p: '8px 12px',
            position: 'relative',
          }}
        >
          <Image
            src={require('../../../assets/home/customer-service.png')}
            sx={{ mr: '12px', width: '24px', height: '24px' }}
          />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: 'sans-serif',
              lineHeight: '1.5',
              textAlign: 'left',
              textDecoration: 'none',
              color: '#fff',
            }}
          >
            {translate('customer_service')}
          </Typography>
        </Box>
      </Box> */}

      <Box sx={{ mb: 1, mt: 2 }}>
        <Typography sx={{ color: 'white', fontSize: '.75rem' }}>{translate('connect_with_us')}</Typography>
        {!configViber &&
        // !config_phonenumber &&
        !configMessenger &&
        !configTelegram &&
        !configFacebook &&
        !configTwitter &&
        !configInstagram &&
        !configLine &&
        !configWechat &&
        !configWhatsapp &&
        !configYoutube ? (
          <></>
        ) : (
          <Grid container>
            {configYoutube && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/yt.png')}
                  onClick={() => openSocial(configYoutube)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}

            {configTwitter && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/twitter.png')}
                  onClick={() => openSocial(configTwitter)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
            {configFacebook && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/fb.png')}
                  onClick={() => openSocial(configFacebook)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px' }}
                />
              </Grid>
            )}
            {configInstagram && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/instagram.png')}
                  onClick={() => openSocial(configInstagram)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
            {/* <Image src={require('../../assets/social/tiktok.png')} alt="Adult" sx={{ width: '30px', height: '30px' }} /> */}
            {configWhatsapp && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/wa.png')}
                  onClick={() => openSocial(configWhatsapp)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
            {configLine && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/line.png')}
                  onClick={() => openSocial(configLine)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
            {configMessenger && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/messenger.png')}
                  onClick={() => openSocial(configMessenger)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
            {configTelegram && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/telegram.png')}
                  onClick={() => openSocial(configTelegram)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
            {configWechat && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/wechat.png')}
                  onClick={() => openSocial(configWechat)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
            {configViber && (
              <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5} sx={{ mt: 1 }}>
                <Image
                  src={require('../../../assets/social/viber.png')}
                  onClick={() => openSocial(configViber)}
                  alt="Adult"
                  sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const navIconStyle = { width: '24px', mr: '12px', mb: 0 };

const NavBtnWrapper = ({ icon, src, title, onClick, sx }) => (
  <Box
    onClick={onClick}
    sx={{
      justifyContent: 'flex-start',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#082d1f',
      borderRadius: '10px',
      cursor: 'pointer',
      p: '8px 12px',
      position: 'relative',
      mb: 1,
      ...sx,
    }}
  >
    {src ? <Image src={src} sx={navIconStyle} alt="History" /> : <></>}
    {icon ? <Iconify icon={icon} sx={navIconStyle} /> : <></>}
    <Typography
      sx={{
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'sans-serif',
        lineHeight: '1.5',
        textAlign: 'left',
        textDecoration: 'none',
        color: '#fff',
      }}
    >
      {title}
    </Typography>
  </Box>
);
