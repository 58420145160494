import { useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

import Login from './Login';
import Register from './Register';
import CustomModal from '../../../../components/CustomModal';

const LoginSignupDialog = ({ setOpenForgotPasswordDialog }) => {
  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const [activeTab, setActiveTab] = useState('1');

  const { translate } = useLocales();

  useEffect(() => {
    if (isOpenLoginSignupDialog?.isLogin) setActiveTab('1');
    if (!isOpenLoginSignupDialog?.isLogin) setActiveTab('2');
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <CustomModal
      fullWidth
      maxWidth="sm"
      sx={{ minHeight: 500 }}
      open={isOpenLoginSignupDialog.open}
      onClose={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
    >
      <Box sx={{ px: { sm: 3, xs: 1 }, py: '50px' }}>
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
        />
        <TabContext value={activeTab}>
          <Box sx={{ mb: 4 }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // indicatorColor="transparent"
              TabIndicatorProps={{
                style: { backgroundColor: 'transparent' },
              }}
              sx={{ background: '#1d2532', width: 'fit-content', minHeight: 'unset', borderRadius: '15px' }}
            >
              <Tab
                label={translate('login')}
                value="1"
                sx={{
                  background: (theme) => (activeTab === '1' ? theme.palette.primary.main : '#1d2532'),
                  minHeight: 'unset',
                  marginRight: '10px !important',
                  p: '5px 10px',
                  fontSize: '14px',
                  borderRadius: '16px',
                }}
              />
              <Tab
                label={translate('register')}
                value="2"
                sx={{
                  background: (theme) => (activeTab === '2' ? theme.palette.primary.main : '#1d2532'),
                  minHeight: 'unset',
                  p: '5px 10px',
                  fontSize: '14px',
                  borderRadius: '16px',
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Login setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} setActiveTab={setActiveTab} />
          </TabPanel>
          <TabPanel value="2">
            <Register setActiveTab={setActiveTab} />
          </TabPanel>
        </TabContext>
      </Box>
    </CustomModal>
  );
};

export default LoginSignupDialog;
