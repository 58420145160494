import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import LoginSignupDialog from './visitor/LoginSignupDialog';
import ForgotPasswordDialog from './forgotpassword/ForgotPasswordDialog';
import ResetPasswordDialog from './forgotpassword/ResetPasswordDialog';
import { useDispatch, useSelector } from '../../../redux/store';
import { openLoginSignup } from '../../../redux/slices/authPages';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

import Image from '../../../components/Image';
import NotificationPopover from './NotificationPopover';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import useLocales from '../../../hooks/useLocales';
import { fNumberDecimal } from '../../../utils/formatNumber';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  backgroundColor: '#082d1f',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { isAuthenticated } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const { memberBalance } = useSelector((x) => x.lookup);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('referralCode') && !localStorage.getItem('accessToken')) {
      setTimeout(() => {
        dispatch(openLoginSignup({ open: true, isLogin: false }));
      }, 1000);
    }

    // Check if got resetpassword token
    if (localStorage.getItem('resetToken')) {
      setOpenResetPasswordDialog(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      // getProfile();
    } else {
      console.log('loggedoff');
    }
  }, [localStorage.getItem('accessToken')]);

  useEffect(() => {
    if (isOpenLoginSignupDialog.open)
      setTimeout(() => {
        loadCaptchaEnginge(4);
      }, 1000);
  }, [isOpenLoginSignupDialog]);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          pl: { lg: 1 },
          pr: { lg: 2 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <Stack direction="row" alignItems={'center'}>
            <Image
              src={require('../../../assets/home/menu.png')}
              alt="Menu"
              sx={{ height: 'auto', width: '40px', transform: 'rotate(180deg)', cursor: 'pointer' }}
              className={`menu-rotate-${isOpenSidebar ? 'close' : 'open'}`}
              onClick={onOpenSidebar}
            />
            <Image
              onClick={() => navigate('/home')}
              src={require('../../../assets/jadewin-app-logo.png')}
              alt="App Logo"
              sx={{ height: '50px', width: '50px', ml: 1, cursor: 'pointer' }}
            />
          </Stack>
        )}

        {isDesktop && (
          <Stack direction="row" alignItems={'center'}>
            <Image
              src={require('../../../assets/home/menu.png')}
              alt="Menu"
              sx={{ height: 'auto', width: '40px', transform: 'rotate(180deg)', cursor: 'pointer' }}
              className={`menu-rotate-${isCollapse ? 'close' : 'open'}`}
              onClick={() => onToggleCollapse()}
            />
            <Image
              onClick={() => navigate('/home')}
              src={require('../../../assets/jadewin-app-logo.png')}
              alt="App Logo"
              sx={{ height: '50px', width: '50px', ml: 1, cursor: 'pointer' }}
            />
          </Stack>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <LanguagePopover />
          {!localStorage.getItem('accessToken') && (
            <>
              <button
                type="button"
                className="visitor-buttons"
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
                style={{
                  height: 'auto',
                  lineHeight: 1.4,
                  position: 'relative',
                  textAlign: 'center',
                  backgroundColor: '#2283f6',
                  color: '#fff',
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  border: 'none',
                  padding: '10px 14px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                {translate('login')}
              </button>
              <m.button
                className="visitor-buttons"
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: false }))}
                animate={{
                  boxShadow: [
                    '0px 0px 5px 0px rgba(32,252,141,0.75)',
                    '0px 0px 15px 0px rgba(32,252,141,0.75)',
                    '0px 0px 5px 0px rgba(32,252,141,0.75)',
                  ],
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  type: 'keyframes',
                  stiffness: 260,
                  damping: 20,
                  repeat: Infinity,
                  repeatDelay: 0,
                  duration: 2.5,
                }}
                style={{
                  height: 'auto',
                  lineHeight: 1.4,
                  position: 'relative',
                  textAlign: 'center',
                  backgroundColor: '#dd234b',
                  color: '#fff',
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  border: 'none',
                  padding: '10px 14px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                {translate('sign_up')}
              </m.button>
            </>
          )}
          {localStorage.getItem('accessToken') && (
            <>
              <Typography
                onClick={() =>
                  dispatch(
                    openTransactionsDialog({
                      open: true,
                      isDeposit: true,
                    })
                  )
                }
                sx={{ cursor: 'pointer', display: { xl: 'block', lg: 'block', md: 'block', sm: 'block', xs: 'none' } }}
              >
                {`${memberBalance?.currencyCode || ''} ${
                  memberBalance?.displayBalance ? fNumberDecimal(memberBalance?.displayBalance) : '0'
                }`}
              </Typography>
              <button
                style={{
                  backgroundColor: '#ff3141',
                  border: 'none',
                  padding: '3px 10px',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  dispatch(
                    openTransactionsDialog({
                      open: true,
                      isDeposit: true,
                    })
                  )
                }
              >
                <Iconify icon={'fluent:wallet-24-filled'} width={20} height={20} sx={{ color: 'white' }} />
              </button>

              <NotificationPopover />

              <AccountPopover />
            </>
          )}
        </Stack>

        {isOpenLoginSignupDialog.open && (
          <LoginSignupDialog setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} />
        )}

        {openForgotPasswordDialog && (
          <ForgotPasswordDialog
            openForgotPasswordDialog={openForgotPasswordDialog}
            setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
          />
        )}

        {openResetPasswordDialog && (
          <ResetPasswordDialog
            openResetPasswordDialog={openResetPasswordDialog}
            setOpenResetPasswordDialog={setOpenResetPasswordDialog}
          />
        )}
      </Toolbar>
    </RootStyle>
  );
}
