// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  game: `Trò chơi`,
  games: `Trò chơi`,
  center: `Trung tâm`,
  game_history: `Lịch sử trò chơi`,
  coupon_code: `mã giảm giá`,
  telegram: `Telegram`,
  customer_service: `Customer Service`,
  player: `Người chơi`,
  winner: `Người chiến thắng`,
  all_bets: `Tất cả cược`,
  my_bets: `Cược của tôi`,
  name: `Tên`,
  time: `Thời gian`,
  bet_amount: `Số tiền đặt cược`,
  prediction: `Sự dự đoán`,
  payout: `Thanh toán`,
  login: `Đăng nhập`,
  register: `Đăng ký`,
  username_placeholder: `tên tài khoản`,
  please_input_your_x: `Vui lòng nhập {{x}} của bạn`,
  captcha: `CAPTCHA`,
  forgot_password: `Quên mật khẩu`,
  minor_note: `Để truy cập trang web này, hãy đảm bảo bạn trên 18 tuổi và đồng ý`,
  terms_of_service: `Điều khoản dịch vụ`,
  dont_have_account: `Bạn chưa có tài khoản?`,
  sign_up: `Đăng ký`,
  username: `tên tài khoản`,
  email: `E-mail`,
  full_name: `Họ và tên`,
  confirm_18: `Tôi xác nhận rằng tôi 18 tuổi và tôi đã đọc`,
  already_have_account: `Bạn co săn san để tạo một tai khoản?`,
  share: `Chia sẻ`,
  deposit: `Tiền gửi`,
  bonus: `Thưởng`,
  app: `App`,
  load_more: `Tải thêm`,
  details: `Chi tiết`,
  amount: `Số lượng`,
  extra: `Thêm`,
  deposit_bonus: `Tiền thưởng gửi tiền`,
  do_not_participate: `Không tham gia các chương trình khuyến mãi`,
  payment_problem: `Vấn đề thanh toán`,
  available_balance: `Số dư khả dụng`,
  withdraw: `Rút`,
  min: `Min:`,
  max: `Max:`,
  notifications: `Thông báo`,
  invalid_x: `{{x}} không hợp lệ`,
  captcha_not_match: `Mã captcha không khớp`,
  invalid_x_must_be_atleast_y_characters: `{{x}} không hợp lệ, phải có ít nhất {{y}} ký tự`,
  x_is_required: `{{x}} là bắt buộc`,
  phone_number: 'Số điện thoại',
  phone_num_x_max: `Số điện thoại tối đa {{x}} chữ số`,
  email_sent: `Đã gửi email, vui lòng kiểm tra email.`,
  enter_valid_email: `Vui lòng nhập email hợp lệ.`,
  confirm: `Xác nhận`,
  password_atleast_6_characters: `Mật khẩu phải có ít nhất 6 ký tự.`,
  password_reset_success: `Đặt lại mật khẩu thành công!.`,
  new_password: `mật khẩu mới`,
  bank: 'Ngân hàng',
  bank_transfer: `Chuyển khoản ngân hàng`,
  bank_name: `Tên ngân hàng`,
  account_name: `Tên tài khoản`,
  account_number: `Số tài khoản`,
  drop_or_select: `Thả hoặc chọn tệp`,
  drop_files_here: `Thả tập tin vào đây`,
  transfer_to: `Chuyển tới`,
  upload_receipt: `Tải lên biên nhận`,
  select_bonus: `Chọn tiền thưởng`,
  no_promotion: `Không có khuyến mãi`,
  please_upload_receipt: `Vui lòng tải lên biên lai`,
  bank_account: `Tài khoản ngân hàng`,
  add_account: `Thêm tài khoản`,
  account_holder_name: `Tên Chủ Tài khoản`,
  name_of_the_account_holder: `Tên chủ tài khoản`,
  fill_in_bank_account: `Điền vào tài khoản ngân hàng`,
  withdraw_information: `Rút thông tin`,
  note: `Ghi chú`,
  amount_withdrawn: `Số tiền đã rút`,
  amount_no_exceed_balance: `Số tiền rút không được vượt quá số dư khả dụng`,
  minimum_withdraw_amount_is_x: `Số tiền rút tối thiểu là {{x}}`,
  maximum_withdraw_amount_is_x: `Số tiền rút tối đa là {{x}}`,
  transaction_history: `Lịch sử giao dịch`,
  date_from: `Từ ngày`,
  date_to: `Ngày tới`,
  account_name_must_be_4: `Tên tài khoản phải dài hơn 4`,
  account_number_must_be_x: `Số tài khoản phải dài 10-14`,
  minimum_length_is_x: `Độ dài tối thiểu là {{x}}`,
  maximum_length_is_x: `Độ dài tối đa là {{x}}`,
  payment_method: `Phương thức thanh toán`,
  mode: `Cách thức`,
  no_bank_account: `Bạn chưa có tài khoản để rút về, Thêm ngay`,
  please_select_bank: `Vui lòng chọn một Tài khoản ngân hàng`,

  bet_history: `Lịch sử đặt cược`,
  game_provider: `Nhà cung cấp trò chơi`,
  bet_count: `Số lần đặt cược`,
  win_loss: `Thắng thua`,
  rebate: `Hạ giá`,
  turnover: `Doanh số`,
  game_category: `Thể loại trò chơi`,

  you_have_x_unread_messages: `Bạn có {{x}} tin nhắn chưa đọc`,
  deposit_amount_between_x_and_y: `Số tiền gửi phải nằm trong khoảng từ {{x}} đến {{y}}`,
  minimum_amount_is_x_y: `Số tiền tối thiểu là {{x}}{{y}}`,
  from_selected_promotion: 'từ chương trình khuyến mãi đã chọn',
  from_selected_bank: 'từ ngân hàng đã chọn',
  deposit_from_bank: 'Nạp tiền từ ngân hàng',
  payment_draft:
    'Nếu bạn xác nhận rằng bạn đã thanh toán, vui lòng đợi một chút vì khoản thanh toán có thể vẫn đang được xử lý',

  // all
  all: `Tất cả`,
  title: `Tiêu đề`,
  date: `Ngày`,
  type: `Kiểu`,
  status: `Trạng thái`,
  remark: `Nhận xét`,
  category: `Loại`,
  please_select_x: `Vui lòng chọn {{x}}`,
  save: `Cứu`,

  change_password: `Đổi mật khẩu`,
  current_password: `Mật khẩu hiện tại`,
  confirm_password: `Xác nhận mật khẩu mới`,
  submit: `Nộp`,
  password_changed: `Mật khẩu đã được thay đổi`,
  x_must_be_y_characters: `{{x}} phải có ít nhất {{y}} ký tự`,
  reset_password: `Đặt lại mật khẩu`,
  casino: `Sòng bạc`,
  slots: `Máy đánh bạc`,
  live_casino: `Sòng bạc trực tiếp`,
  fishing: `Đánh bắt cá`,
  sports: `Các môn thể thao`,
  cards: `thẻ`,
  clear: `Thông thoáng`,
  search: `Tìm kiếm`,
  new: `Mới`,
  logout: `Đăng xuất`,
  choose_date: `Chọn ngày`,
  select_both_date: `Chọn cả Ngày Từ và Đến`,
  from: `Từ`,
  to: `ĐẾN`,
  apply: `Áp dụng`,
  promotions: `Khuyến mãi`,
  learn_more: `TÌM HIỂU THÊM`,
  password_doesnt_match: `Mật khẩu không khớp`,
  cancel: `Hủy bỏ`,
  confirm_to_pay: `Xác nhận thanh toán`,
  confirm_to_withdraw: `Xác nhận rút tiền`,
  deposit_amount: `Số tiền gửi`,
  loading_please_wait: `Tải vui lòng đợi`,
  account: `Tài khoản`,
  profile: `Hồ sơ`,
  bank_list: `Danh sách ngân hàng`,
  in_maintenance: `Đang bảo trì`,
  birthday: `Sinh nhật`,
  birthday_bonus: `Nhập ngày sinh của bạn để nhận tiền thưởng! 🎉🎁`,
  referral_code: `Mã giới thiệu`,
  referral: `Giới thiệu`,
  my_referral: `Lời giới thiệu của tôi`,
  my_claim: `Yêu cầu của tôi`,
  claim_profit: `YÊU CẦU LỢI NHUẬN`,
  available_profit: `Lợi nhuận khả dụng`,
  claimed_profit: `Lợi nhuận được yêu cầu`,
  all_time_profit: `Lợi nhuận mọi thời đại`,
  referral_note_on_x: `Kiếm lợi nhuận mỗi khi bạn bè của bạn chơi trên {{x}}`,
  tier: `Tầng`,
  commission: `Nhiệm vụ`,
  referred_member: `Thành viên được giới thiệu`,
  profit_earned: `Lợi nhuận kiếm được`,
  account_information: `thông tin tài khoản`,
  invite_friends: `Mời bạn bè`,
  how_it_work: `Nó hoạt động như thế nào?`,
  invite_note: `Nhận phần thưởng giới thiệu độc quyền khi bạn bè của bạn đăng ký bằng mã giới thiệu của bạn!`,
  share_via: `Chia sẻ qua`,
  referral_link: `Liên kết giới thiệu`,
  total_profit: `Tổng lợi nhuận`,
  copied: `Đã sao chép`,
  nothing_to_claim: `Không có gì để yêu cầu`,
  claim_history: `Lịch sử yêu cầu`,
  downline: `Tuyến dưới của tôi`,
  last_x_digit_bank_ref_number: `{{x}} chữ số cuối cùng của số Tham chiếu Ngân hàng`,
  bank_ref_number_validation_x: `Số tham chiếu ngân hàng là bắt buộc và phải có độ dài {{x}} ký tự`,
  sort: 'Loại',
  copy_your_referral:
    'Sao chép liên kết giới thiệu của bạn và gửi tới phương tiện truyền thông xã hội và kiếm tín dụng từ tuyến dưới của bạn!',
  how_to_get_referral_bonus: 'Làm thế nào để nhận được tiền thưởng giới thiệu bạn bè?',
  steps_to_claim: 'Các bước để nhận tiền thưởng',
  share_your_referral_code:
    '1. Chia sẻ mã giới thiệu của bạn cho bạn bè để đăng ký tài khoản qua viber, mesenger, telegram, facebook, twitter hoặc instagram.',
  after_friend_register:
    '2. Sau khi bạn bè đăng ký với ZK7, cả người giới thiệu và thành viên được giới thiệu đều phải gửi tiền để nhận phần thưởng này.',
  refer_a_friend_bonus: '3. Tính thưởng giới thiệu bạn bè:',
  turnover_played: 'Doanh thu đã chơi x Hoa hồng % = Tiền thưởng giới thiệu bạn bè',
  example: 'Ví dụ:',
  turnover_formula: 'Doanh thu (1.000.000) x 0,002% (% hoa hồng) = 2.000MMK',
  note_bonus_only: '*Lưu ý: Tiền thưởng chỉ có thể nhận vào sau 12h trưa ngày hôm sau',
  note_bonus_only_x: '*Lưu ý: Tiền thưởng chỉ có thể nhận vào sau {{x}} ngày hôm sau',
  access_dashboard:
    '4. Truy cập bảng điều khiển để kiểm tra lợi nhuận được hưởng, bậc hoa hồng, lịch sử yêu cầu và tuyến dưới.',
  click_this_button_to_claim: 'Nhấp vào nút này để nhận Tiền thưởng giới thiệu bạn bè',
  commision_tier: 'Bậc hoa hồng',
  member_downline: 'Tuyến dưới của thành viên',
  bonus_tutorial_video: 'Video hướng dẫn thưởng',
  popularity: 'Phổ biến',
  no_balance: 'Bạn không có đủ số dư để tiếp tục',
  default: 'Mặc định',
  claim_bonus: 'Yêu cầu tiền thưởng',
  claimed: 'Đã xác nhận quyền sở hữu',
  bonus_claimed: 'Đã nhận tiền thưởng',
  connect_with_us: `Kết nối với chúng tôi`,
  level: `Mức độ`,
  online_banking: 'Ngân hàng trực tuyến',
  brands: 'Nhãn hiệu',
  no_result: 'Không kết quả',
};

export default vn;
