import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Checkbox,
  Grid,
  InputBase,
  Snackbar,
  Stack,
  Typography,
  TextField,
  FormControlLabel,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplateNoReload,
//   validateCaptcha,
// } from 'react-simple-captcha';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import PhonenumberTextField from '../../../../components/PhonenumberTextField';
import useLocales from '../../../../hooks/useLocales';
import useAuth from '../../../../hooks/useAuth';
import Image from '../../../../components/Image';

import { useDispatch, useSelector } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

const _registerForm = {
  userUserName: '',
  userPassword: '',
  userPhoneNumber: '',
  userFullName: '',
  // userEmail: '',
  // userBirthday: null,
  userReferralCode: '',
  // captcha: '',
};

const Register = ({ setActiveTab }) => {
  const dispatch = useDispatch();

  const { siteSettings } = useSelector((x) => x.lookup);

  const { register } = useAuth();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [registerForm, setRegisterForm] = useState(_registerForm);

  const [formError, setFormError] = useState({});

  const [tncConfirmed, setTncConfirmed] = useState(false);

  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
  });

  const [validationErrors, setValidationErrors] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { config_telegram: configTelegram, config_viber: configViber } = siteSettings || {};

  useEffect(() => {
    const _referralCode = localStorage.getItem('referralCode');

    if (_referralCode) {
      setRegisterForm((prevState) => ({ ...prevState, userReferralCode: _referralCode }));
      localStorage.removeItem('referralCode');
    }

    // setTimeout(() => {
    //   loadCaptchaEnginge(4);
    // }, 1000);
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setRegisterForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async () => {
    if (!isValid()) return;

    setIsLoading(true);

    const requestData = {
      'user-username': registerForm?.userUserName.trim(),
      'user-password': registerForm?.userPassword.trim(),
      'user_profile-no_hp': registerForm?.userPhoneNumber,
      'user-full_name': registerForm?.userFullName,
      // 'user-email': registerForm?.userEmail,
      // 'user_profile-birthday': moment(registerForm?.userBirthday).format('YYYY-MM-DD'),
      'user-referral_code': registerForm?.userReferralCode,
    };

    try {
      await register(requestData);
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Enter key handler');
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [registerForm]);

  const isValid = () => {
    let valid = true;
    const _error = {};

    // username
    if (registerForm?.userUserName?.trim()?.length < 3) {
      valid = false;
      _error.userUserName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '3' });
    } else {
      _error.userUserName = '';
    }

    // password
    if (registerForm?.userPassword?.trim()?.length < 5) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '5' });
    } else {
      _error.userPassword = '';
    }

    // fullname
    if (!registerForm?.userFullName.trim()) {
      valid = false;
      _error.userFullName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('full_name'), y: '1' });
    } else {
      _error.userFullName = '';
    }

    // email
    // if (!registerForm?.userEmail.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
    //   valid = false;
    //   _error.userEmail = translate('invalid_x', { x: translate('email') });
    // } else {
    //   _error.userEmail = '';
    // }

    // phone number
    if (!registerForm?.userPhoneNumber.trim()) {
      valid = false;
      _error.userPhoneNumber = translate('x_is_required', { x: translate('phone_number') });
    } else if (registerForm?.userPhoneNumber.trim().length < process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate(`invalid_x_must_be_atleast_y_characters`, {
        x: translate('phone_number'),
        y: process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH,
      });
    } else if (registerForm?.userPhoneNumber.trim().length > process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate('phone_num_x_max', { x: process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH });
    } else {
      _error.userPhoneNumber = '';
    }

    // birthday
    // if (!registerForm?.userBirthday) {
    //   valid = false;
    //   _error.userBirthday = translate('x_is_required', { x: translate('birthday') });
    // } else {
    //   _error.userBirthday = '';
    // }

    // if (validateCaptcha(registerForm?.captcha?.trim(), false) === true) {
    //   _error.userCaptcha = '';
    // } else {
    //   valid = false;
    //   _error.userCaptcha = translate('captcha_not_match');
    // }

    setFormError((prevState) => _error);

    return valid;
  };

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '10px' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      <Box sx={{ mb: '10px' }}>
        <PhonenumberTextField
          name="userPhoneNumber"
          value={registerForm?.userPhoneNumber}
          onChange={(e) => setRegisterForm((prevState) => ({ ...prevState, userPhoneNumber: e }))}
          fullWidth
          sx={{ color: 'white' }}
        />
        {formError?.userPhoneNumber && (
          <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userPhoneNumber}</span>
        )}
      </Box>
      <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'mdi-light:account'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            name="userUserName"
            value={registerForm?.userUserName}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('username') })}
            //   InputLabelProps={{
            //     style: { color: '#999999' },
            //  }}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: '#fff' }}
          />
        </Stack>
        {formError?.userUserName && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userUserName}</span>}
      </Box>
      {/* <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'mdi-light:email'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            name="userEmail"
            value={registerForm?.userEmail}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('email') })}
            //   InputLabelProps={{
            //     style: { color: '#999999' },
            //  }}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: '#fff' }}
          />
        </Stack>
        {formError?.userEmail && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userEmail}</span>}
      </Box> */}

      <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'carbon:data-2'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            name="userFullName"
            value={registerForm?.userFullName}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('full_name') })}
            //   InputLabelProps={{
            //     style: { color: '#999999' },
            //  }}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: '#fff' }}
          />
        </Stack>
        {formError?.userFullName && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userFullName}</span>}
      </Box>

      {/* <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'clarity:calendar-line'} width={24} height={24} sx={{ color: '#55657e' }} />
          <MobileDatePicker
            label={translate('birthday')}
            value={registerForm.userBirthday}
            inputFormat="dd/MM/yyyy"
            onChange={(v) => setRegisterForm((prevState) => ({ ...prevState, userBirthday: v }))}
            renderInput={(params) => (
              <InputBase
                {...params}
                variant="outlined"
                size="small"
                placeholder={translate('please_input_your_x', { x: translate('birthday') })}
                sx={{
                  width: '100%',
                  ml: 1,
                  fontSize: '14px',
                  fontWeight: 600,
                  fontFamily: 'sans-serif',
                  color: '#fff',
                }}
                // error={!!formError?.userBirthday}
                // helperText={formError?.userBirthday}
              />
            )}
            maxDate={new Date()}
          />
        </Stack>
        <Typography style={{ fontSize: '.75rem', color: '#c9c9c9', fontWeight: 500 }}>
          {translate('birthday_bonus')}
        </Typography>
        {formError?.userBirthday && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userBirthday}</span>}
      </Box> */}

      <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            borderRadius: '10px',
            border: '1px solid #202a39',
            height: '44px',
            p: '8.5px 13px',
            mt: '10px',
          }}
        >
          <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            type="password"
            name="userPassword"
            value={registerForm?.userPassword}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('password') })}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
          />
        </Stack>
        {formError?.userPassword && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userPassword}</span>}
      </Box>

      <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'solar:share-circle-outline'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            name="userReferralCode"
            value={registerForm?.userReferralCode}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('referral_code') })}
            //   InputLabelProps={{
            //     style: { color: '#999999' },
            //  }}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: '#fff' }}
          />
        </Stack>
      </Box>

      {/* <Box sx={{ mb: '10px' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
        >
          <Iconify icon={'iconamoon:shield-yes-thin'} width={24} height={24} sx={{ color: '#55657e' }} />
          <InputBase
            name="captcha"
            placeholder={translate('captcha')}
            value={registerForm?.captcha}
            onChange={(e) => handleFormChange(e)}
            sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
          />
          <Box sx={{ mb: '-5px' }}>
            <LoadCanvasTemplateNoReload />
          </Box>
        </Stack>
        {formError?.userCaptcha && <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userCaptcha}</span>}
      </Box> */}

      <Stack direction={'row'} alignItems={'center'}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ width: 'fit-content' }}
              value={tncConfirmed}
              checked={tncConfirmed}
              onChange={(e) => setTncConfirmed(e.target.checked)}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: 'sans-serif',
                fontWeight: 500,
                color: '#55657e',
                cursor: 'pointer',
              }}
            >
              {translate('confirm_18')}{' '}
              <span style={{ textDecoration: 'underline', color: '#2283f6' }}>{translate('terms_of_service')}</span>
            </Typography>
          }
        />
      </Stack>

      <Stack sx={{ mb: 4 }} spacing={1}>
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            color: '#55657e',
            textDecoration: 'underline',
            mt: 2,
          }}
        >
          {`${translate('forgot_password')}? ${translate('contact_us_through')}`}
        </Typography>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {configTelegram && (
            <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5}>
              <Image
                src={require('../../../../assets/social/telegram.png')}
                onClick={() => openSocial(configTelegram)}
                alt="Adult"
                sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
              />
            </Grid>
          )}
          {configViber && (
            <Grid item xl={12 / 5} lg={12 / 5} md={12 / 5} sm={12 / 5} xs={12 / 5}>
              <Image
                src={require('../../../../assets/social/viber.png')}
                onClick={() => openSocial(configViber)}
                alt="Adult"
                sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
              />
            </Grid>
          )}
        </Stack>
      </Stack>

      {/* <Typography
        sx={{
          fontSize: '14px',
          fontFamily: 'sans-serif',
          fontWeight: '400',
          color: '#55657e',
          cursor: 'pointer',
          textDecoration: 'underline',
          mt: 2,
          mb: 4,
        }}
      >
        {`${translate('forgot_password')}?`}
      </Typography> */}
      <LoadingButton
        variant="contained"
        onClick={() => onSubmit()}
        disabled={
          !tncConfirmed ||
          !registerForm?.userUserName ||
          // !registerForm?.captcha ||
          // !registerForm?.userEmail ||
          !registerForm.userFullName ||
          !registerForm?.userPassword ||
          !registerForm?.userPhoneNumber
        }
        sx={{
          width: '100%',
          mb: 1,
        }}
      >
        {translate('sign_up')}
      </LoadingButton>

      <Typography
        onClick={() => setActiveTab('1')}
        sx={{
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'sans-serif',
          color: 'white',
          mt: 2,
          cursor: 'pointer',
        }}
      >
        {translate('already_have_account')}{' '}
        <span style={{ textDecoration: 'underline', color: '#2283f6' }}>{translate('login')}</span>
      </Typography>
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Register.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Register;
