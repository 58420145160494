import { useEffect, useState } from 'react';
// @mui
import { Button, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { useSelector } from '../../../redux/store';
// import { updateHeaders } from '../../../utils/connection-assistant';

// ----------------------------------------------------------------------

export default function LanguagePopover({ sx = {} }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [customLangs, setCustomLangs] = useState([]);

  const [open, setOpen] = useState(null);

  const [selectedLang, setSelectedLang] = useState(currentLang);

  const domainLocale = process.env.REACT_APP_LOCALE;

  const langStorage = localStorage.getItem('i18nextLng');

  const [paramLang, setParamLang] = useState('');

  useEffect(() => {
    // setCustomLangs(allLangs.filter((f) => f.value === 'en' || f.value === domainLocale));
    if (siteSettings?.languages?.length > 0) {
      const matchingLangs = allLangs.filter((obj1) =>
        siteSettings?.languages.some((obj2) => obj1.value === obj2.locale)
      );

      if (matchingLangs) {
        setCustomLangs(matchingLangs);

        if (matchingLangs.findIndex((ml) => ml.value === langStorage) === -1) {
          handleChangeLang(matchingLangs.find((f) => f.value === domainLocale)?.value);
          console.log('matching');
        }
      } else {
        console.log('No Matching languages');
      }
    }
  }, [siteSettings]);

  useEffect(() => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    setParamLang((prev) => {
      if (!prev) {
        return urlParams.get('lang');
      }
      return '';
    });
  }, []);

  useEffect(() => {
    if (customLangs?.length && paramLang) {
      const isExist = customLangs.find((x) => x.value === paramLang.toLowerCase());

      if (isExist) {
        handleChangeLang(isExist?.value);
        setParamLang('');
      }
    }
  }, [customLangs, paramLang]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    // localStorage.setItem('i18nextLng', newLang);
    onChangeLang(newLang);
    handleClose();
    // updateHeaders({ 'X-Language': newLang });
    window.location.reload();
  };

  return (
    <>
      <Button
        variant={'text'}
        onClick={handleOpen}
        startIcon={<Image disabledEffect src={currentLang.icon} alt={currentLang.label} sx={{ width: 28 }} />}
        sx={sx}
        size="small"
      >
        <Typography fontWeight={700}>{currentLang.value?.toUpperCase()}</Typography>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {customLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                handleChangeLang(option.value);
                setSelectedLang(option);
              }}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
