import { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Iconify from '../../components/Iconify';
import useLocales from '../../hooks/useLocales';

import { useDispatch, useSelector } from '../../redux/store';

import CustomModal from '../../components/CustomModal';
import Image from '../../components/Image';
import { openClaimAngpaoDialog } from '../../redux/slices/claimAngpaoDialog';

const BonusAmountText = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  position: 'absolute',
  left: 0,
  right: 0,
  textAlign: 'center',
  zIndex: 1,
  textShadow: '2px 2px 2px black',
  [theme.breakpoints.up('sm')]: {
    fontSize: '4rem',
    bottom: '8rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '8vw',
    bottom: '15vw',
  },
}));

const ClaimAngpaoDialog = () => {
  const dispatch = useDispatch();

  const { openClaimAngpao } = useSelector((x) => x.claimAngpaoDialog);

  return (
    <CustomModal
      fullWidth
      maxWidth="sm"
      sx={{ minHeight: 500, background: 'transparent' }}
      open={openClaimAngpao?.open}
      onClose={() => dispatch(openClaimAngpaoDialog({ open: false, bonusAmount: '' }))}
    >
      <Box sx={{ position: 'relative' }}>
        <BonusAmountText>{openClaimAngpao?.bonusAmount}</BonusAmountText>
        <Image src={require('../../assets/promotion/claimAngpao.gif')} />
      </Box>
    </CustomModal>
  );
};

export default ClaimAngpaoDialog;
