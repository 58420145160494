import React from 'react';
import axios from 'axios';
// import { Redirect, useHistory } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';

import app from '../App';

const BASE_HEADERS = {
  // headers, without this, it will throw unsupported media type error
  // 'content-type': 'application/json; charset=utf-8',
  'content-type': 'multipart/form-data; charset=utf-8',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  Accept: 'application/json',
  // Authorization: "Bearer " + localStorage.getItem("jwt")
  'X-Language': localStorage.getItem('i18nextLng') || process.env.REACT_APP_LOCALE,
};

/**
 * @description
 * axios instance with default
 * baseUrl & headers etc. for global use
 */
const conObj = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: BASE_HEADERS,
});

conObj.interceptors.request.use((request) => {
  // Need to check if jwt already expired?
  /**
   * Moved this piece of code here so that localStorage.getItem("jwt")
   * is always getting the latest value.
   * If follow old settings, still need to refresh the whole site to
   * reflect the latest localStorage values
   * */
  // request.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  request.data = {
    ...request.data,
    device: 'web', // isMobile ? 'mobile' : 'web',
    appversion: process.env.REACT_APP_VERSION,
    passkey: process.env.REACT_APP_PASSKEY,
    ...(localStorage.getItem('accessToken') && { token: localStorage.getItem('accessToken') }),
  };
  // saveLogs(request);
  return request;
});

conObj.interceptors.response.use(
  (response) =>
    // saveLogs(response);
    {
      if (typeof response.data?.data?.status !== 'undefined' && response.data?.data?.status !== 1) {
        if (response.data?.data?.status === -999) {
          // kickout user
          localStorage.clear();
          window.location.reload();
        }
        // because validationErrors is more detailed, show only if it has
        if (response.data?.data?.validationErrors) throw new Error(response.data?.data?.validationErrors.toString());

        throw new Error(response?.data?.data?.msg);
      }
      return response;
    },
  (error) => {
    if (!axios.isCancel(error)) {
      if (error?.response?.status === 401) {
        // const lastPath = window.location.hash;
        // sessionStorage.setItem('lastPath', lastPath.substring(1, lastPath.length));
        window.location.replace('/');
      }
      return Promise.reject(error);
    }
    console.log('Cancelled');
  }
);

// function saveLogs(req_data) {
//   const data = {
//     secret: 'secret',
//     req_data: req_data,
//     jwt: localStorage.getItem('jwt'),
//   };
//   axios
//     .post(process.env.REACT_APP_LOGGER_API, data, axiosConfig)
//     .then((res) => {
//       //do nothing
//     })
//     .catch((err) => {
//       console.log('Something went wrong while calling save logs: ' + err);
//     });
// }

export const updateHeaders = (newHeaders) => {
  const baseHeaders = {
    ...BASE_HEADERS,
    ...newHeaders,
  };

  // Create a new Axios instance with updated headers
  conObj.defaults.headers = baseHeaders;
};

// Only use for POST/PUT/DELETE API Actions
export const showResponseError = (error, enqueueSnackbar) => {
  if (!enqueueSnackbar) return console.error('enqueueSnackbar parameter is missing');
  const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
  const errorMessages = error?.response?.data?.errors;
  let errorMessage = error?.response?.data?.message || error?.response?.data?.internalMessage;

  if (typeof errorMessage === 'string') {
    try {
      errorMessage += `: ${[...error?.response?.data?.errorData].join(', ')}`;
    } catch (err) {
      /// ignore
    }
    enqueueSnackbar(errorMessage, snackbarOptions);
  } else if (errorMessages && Object.keys(errorMessages).length) {
    Object.keys(errorMessages).forEach((key) => enqueueSnackbar(errorMessages[key], snackbarOptions));
  } else enqueueSnackbar(`Server Error: ${error.message}`, snackbarOptions);

  return true;
};

export default conObj;
