// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  game: `Game`,
  games: `Games`,
  center: `Center`,
  game_history: `Game History`,
  coupon_code: `Coupon Code`,
  telegram: `Telegram`,
  customer_service: `Customer Service`,
  player: `Player`,
  winner: `Winner`,
  all_bets: `All Bets`,
  my_bets: `My Bets`,
  name: `Name`,
  time: `Time`,
  bet_amount: `Bet Amount`,
  prediction: `Prediction`,
  payout: `Payout`,
  login: `Login`,
  register: `Register`,
  username_placeholder: `Username`,
  please_input_your_x: `Please Input Your {{x}}`,
  captcha: `CAPTCHA`,
  forgot_password: `Forgot Password`,
  minor_note: `To visit this site, make sure you are over 18 and agree`,
  terms_of_service: `Terms of Service`,
  dont_have_account: `Don't have an account?`,
  sign_up: `Sign Up`,
  username: `Username`,
  email: `Email`,
  full_name: `Full Name`,
  confirm_18: `I confirm that I am 18 years old and I have read the`,
  already_have_account: `Already have an account?`,
  share: `Share`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Load More`,
  details: `Details`,
  amount: `Amount`,
  extra: `Extra`,
  deposit_bonus: `Deposit Bonus`,
  do_not_participate: `Do not participate in promotions`,
  payment_problem: `Payment Problem`,
  available_balance: `Available Balance`,
  withdraw: `Withdraw`,
  min: `Min:`,
  max: `Max:`,
  notifications: `Notifications`,
  invalid_x: `Invalid {{x}}`,
  captcha_not_match: `Captcha Does Not Match`,
  invalid_x_must_be_atleast_y_characters: `Invalid {{x}}, must be atleast {{y}} characters`,
  x_is_required: `{{x}} is required`,
  phone_number: 'Phone Number',
  phone_num_x_max: `Phone Number maximum {{x}} digits`,
  email_sent: `Email sent, please check your email.`,
  enter_valid_email: `Please enter a valid email.`,
  confirm: `Confirm`,
  password_atleast_6_characters: `Password must be atleast 6 characters.`,
  password_reset_success: `Password reset success!.`,
  new_password: `New Password`,
  bank: 'Bank',
  bank_transfer: `Bank Transfer`,
  bank_name: `Bank Name`,
  account_name: `Account Name`,
  account_number: `Account Number`,
  drop_or_select: `Drop or Select file`,
  drop_files_here: `Drop files here`,
  transfer_to: `Transfer To`,
  upload_receipt: `Upload Receipt`,
  select_bonus: `Select Bonus`,
  no_promotion: `No Promotion`,
  please_upload_receipt: `Please upload receipt`,
  bank_account: `Bank Account`,
  add_account: `Add Account`,
  account_holder_name: `Account Holder Name`,
  name_of_the_account_holder: `Name of the Account Holder`,
  fill_in_bank_account: `Fill in Bank Account`,
  withdraw_information: `Withdraw Information`,
  note: `Note`,
  amount_withdrawn: `Amount withdrawn`,
  amount_no_exceed_balance: `Withdraw Amount should not exceed the available balance`,
  minimum_withdraw_amount_is_x: `Minimum Withdraw amount is {{x}}`,
  maximum_withdraw_amount_is_x: `Maximum Withdraw Amount is {{x}}`,
  transaction_history: `Transaction History`,
  date_from: `Date From`,
  date_to: `Date To`,
  account_name_must_be_4: `Account Name must be greater than 4 in length`,
  account_number_must_be_x: `Account number must be 10-14 in length`,
  minimum_length_is_x: `Minimum length is {{x}}`,
  maximum_length_is_x: `Maximum length is {{x}}`,
  payment_method: `Payment Method`,
  mode: `Mode`,
  no_bank_account: `You do not have any account to withdraw to, Add now`,
  please_select_bank: `Please select a Bank Account`,

  bet_history: `Bet History`,
  game_provider: `Game Provider`,
  bet_count: `Bet Count`,
  win_loss: `Win/Loss`,
  rebate: `Rebate`,
  turnover: `Turnover`,
  game_category: `Game Category`,

  you_have_x_unread_messages: `You have {{x}} unread messages`,
  deposit_amount_between_x_and_y: `Deposit amount must be between {{x}} and {{y}}`,
  minimum_amount_is_x_y: `Minimum amount is {{x}}{{y}}`,

  // TODO: Translate to other Lang!
  from_selected_promotion: 'from selected promotion',
  from_selected_bank: 'from selected bank',
  deposit_from_bank: 'Deposit From Bank',
  payment_draft:
    'If you confirm you have already paid, please wait for a while as the payment might still be processing',
  // ENDTODO

  // all
  all: `All`,
  title: `Title`,
  date: `Date`,
  type: `Type`,
  status: `Status`,
  remark: `Remark`,
  category: `Category`,
  please_select_x: `Please select {{x}}`,
  save: `Save`,

  change_password: `Change Password`,
  current_password: `Current Password`,
  confirm_password: `Confirm New Password`,
  submit: `Submit`,
  password_changed: `Password Changed`,
  x_must_be_y_characters: `{{x}} must be at least {{y}} characters`,
  reset_password: `Reset Password`,
  casino: `Casino`,
  slots: `Slots`,
  live_casino: `Live Casino`,
  fishing: `Fishing`,
  sports: `Sports`,
  cards: `Cards`,
  clear: `Clear`,
  search: `Search`,
  new: `New`,
  logout: `Logout`,
  choose_date: `Choose Date`,
  select_both_date: `Select both Date From and To`,
  from: `From`,
  to: `To`,
  apply: `Apply`,
  promotions: `Promotions`,
  learn_more: `LEARN MORE`,
  password_doesnt_match: `Password doesn't match`,
  cancel: `Cancel`,
  confirm_to_pay: `Confirm To Pay`,
  confirm_to_withdraw: `Confirm To Withdraw`,
  deposit_amount: `Deposit Amount`,
  loading_please_wait: `Loading, Please Wait`,
  account: `Account`,
  profile: `Profile`,
  bank_list: `Bank List`,
  in_maintenance: `In Maintenance`,
  birthday: `Birthday`,
  birthday_bonus: `Enter your birthday for a bonus! 🎉🎁`,
  referral_code: `Referral Code`,
  referral: `Referral`,
  my_referral: `My Referral`,
  my_claim: `My Claim`,
  claim_profit: `CLAIM PROFIT`,
  available_profit: `Available Profit`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `All Time Profit`,
  referral_note_on_x: `Earn profit every time your friends playing on {{x}}`,
  tier: `Tier`,
  commission: `Commission`,
  referred_member: `Referred Member`,
  profit_earned: `Profit Earned`,
  account_information: `Account Information`,
  invite_friends: `Invite Friends`,
  how_it_work: `How it work?`,
  invite_note: `Get exclusive referral bonuses when your friend signs up using your referral code!`,
  share_via: `Share via`,
  referral_link: `Referral Link`,
  total_profit: `Total Profit`,
  copied: `Copied`,
  nothing_to_claim: `Nothing to claim`,
  claim_history: `Claim History`,
  downline: `My Downline`,
  last_x_digit_bank_ref_number: `Last {{x}} digit of Bank Ref number`,
  bank_ref_number_validation_x: `Bank Ref Number is required and must be {{x}} character length`,
  sort: 'Sort',
  copy_your_referral: 'Copy your referral link & send to social media and earn credit from your downline!',
  how_to_get_referral_bonus: 'How To Get Referral Friend Bonus?',
  steps_to_claim: 'Steps To Claim Bonus',
  share_your_referral_code:
    '1. Share your referral code to your friends to register account via viber, messenger, telegram, facebook, twitter, or instagram.',
  after_friend_register:
    '2. After friend register with ZK7, both referral and referral member required to make deposit to claim this bonus.',
  refer_a_friend_bonus: '3. Refer friend bonus calculation:',
  turnover_played: 'Turnover played x Commision % = Referral Friend Bonus',
  example: 'Example:',
  turnover_formula_x: 'Turnover (1,000,000) x 0.002% (Commission %) = 2,000{{x}}',
  note_bonus_only: '*Note: Bonus only able to claim on the next day after 12:00am',
  note_bonus_only_x: '*Note: Bonus only able to claim on the next day after {{x}}',
  access_dashboard: '4. Access dashboard to check entitled profit, commision tier, claim history, and downline.',
  click_this_button_to_claim: 'Click this button to claim Referral Friend Bonus',
  commision_tier: 'Commission Tier',
  member_downline: 'Member Downline',
  bonus_tutorial_video: 'Bonus Tutorial Video',
  popularity: 'Popularity',
  no_balance: 'You do not have enough balance to proceed',
  default: 'Default',
  claim_bonus: 'Claim Bonus',
  claimed: 'Claimed',
  bonus_claimed: 'Bonus Claimed',
  connect_with_us: `Connect with us`,
  level: `Level`,
  online_banking: 'Online Banking',
  brands: 'Brands',
  no_result: 'No Result',
  contact_us_through: `Contact us through:`,
};

export default en;
